<template>
  <div :class="{ 'map-menu-container': true, 'pointer-events-none': disable }">
    <!-- <div class="map-menu-btn-container">
      <div class="map-menu-btn" v-for="i in 7" :key="i"></div>
    </div> -->
    <div
      :id="`map-menu-layer-${i}`"
      class="map-menu-layer no-select"
      @click="clickItem(i)"
      v-for="i in 8"
      :key="i"
    >
      <img
        :src="require(`@/assets/images/gallery1/menu/map-menu-${i - 1}@2x.png`)"
        width="100%"
        height="auto"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { gsap, Power2 } from "gsap";

export default {
  data() {
    return {
      disable: true,
      tl: null,
      tlMouse: [null, null, null, null, null, null, null, null],
      tlAll: null,
    };
  },
  mounted() {
    const vm = this;
    this.$store.commit("updateCurrentMap",-1);
    this.tl = new gsap.timeline();
    this.tl.set(".map-menu-layer", {
      transform: "translateY(300px)",
      opacity: 0,
    });
    this.tl.to(".map-menu-layer", {
      transform: "translateY(0)",
      duration: 1,
      opacity: 1,
      stagger: {
        amount: 1.6
      },
      onComplete: function() {
        vm.disable = false;
      },
      ease: Power2.easeOut,
    });

    document.querySelector("html,body").style.overflow = "hidden";

  },
  methods: {
    clickItem(i) {
      const vm = this;
      this.disable = true;
      const tl = new gsap.timeline();
      this.tl.to(`.map-menu-layer:not(#map-menu-layer-${i})`, {
        opacity: 0,
        duration: 1,
        ease: Power2.easeInOut,
      });
      this.tl.to(
        `#map-menu-layer-${i}`,
        {
          bottom: "45vh",
          duration: 1.5,
          ease: Power2.easeInOut,
        },
        "-=.3"
      );
      this.tl.to(
        `#map-menu-layer-${i}`,
        {
          transform: "scale(3)",
          opacity: 0,
          duration: 1,
          ease: Power2.easeInOut,
          onComplete: function() {
            vm.gotoMap(i - 1);
          },
        },
        "-=.3"
      );
    },
    gotoMap(i) {
      this.$router.push({ name: `Map-${i}` });
    },
  },
};
</script>

<style lang="scss">
.map-menu-container {
  width: 100vw;
  height: 100vh;
  &:hover .map-menu-layer {
    opacity: 0.3 !important;
  }
}
.map-menu-container [id^="map-menu-layer"] {
  &:hover {
    opacity: 1 !important;
  }
}
.map-menu-layer {
  margin-bottom: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  width: auto;
  height: calc(100vh / 6);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.4s ease-out;

  img {
    object-fit: contain;
  }
  &:nth-child(1) {
    bottom: 4vh;
  }
  &:nth-child(2) {
    bottom: 17vh;
  }
  &:nth-child(3) {
    bottom: 31vh;
  }
  &:nth-child(4) {
    bottom: 43vh;
  }
  &:nth-child(5) {
    bottom: 53vh;
  }
  &:nth-child(6) {
    bottom: 64vh;
  }
  &:nth-child(7) {
    bottom: 75vh;
  }
  &:nth-child(8) {
    bottom: 85vh;
  }
}

.map-menu-btn-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
}

.map-menu-btn {
  width: 20vw;
  height: 5vh;
  position: absolute;
  left: 25vw;
  bottom: 10vw;
  z-index: 99;
  background: rgba(255, 255, 255, 0.4);
}
</style>
